.check {
  //   background-color: cyan;
  z-index: 10;
  width: 100%;

  // max-height: 45%;
  // min-height: 45%;
  // height:45%;
  //   border: red 2px solid;
  .titleContainer {

    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      max-width: 90vw;
      color: #000;
      letter-spacing: 2px;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
      // background-color: green;

      @media only screen and (max-width: 1080px) {
        font-size: 25px;
      }
    }

  }

  .mainRow {
    //   background-color: darkblue;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;

    h2 {
      text-align: center;
      font-size: 30px;
      //   border: 3px yellow solid;
      height: 100px;
      width: 300px;

      @media only screen and (max-width: 950px) {
        display: none;
      }

      img {
        // border: 3px yellow solid;
      }
    }
  }

  .card {
    max-height: 250px;
    width: auto;

    @media only screen and (max-width: 1023px) {
      max-height: 200px;
    }

    @media only screen and (max-width: 803px) {
      max-height: 120px;
    }

    @media only screen and (max-height: 570px) {
      max-height: 85px;
    }

  }
}

.home__header {
  width: 100vw;
  min-height: calc(100vh - 60px);
  // background-image: url(/static/media/pic_home_background.c0b8e6e2.jpg);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
  background-color: "transparent";
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  
}

.bottom {

  width: 100%;
  height: 55%;
  max-height: 55%;
  min-height: 55%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-image: url(../../assets/images/home/header7.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  overflow: hidden;
  // border: green 2px solid;

  button {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}


.p4pLogo {
  width: 300px;
  height: 300px;
  max-height: 100%;
  background-image: url('../../assets/images/home/p4pLogo.png');
  // sorround with yellow color outside the image
  background-color: #fdd384;
  //expand background horiontally
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 1150px) {
    display: none;
  }
}

.p4pLogo:hover {
  // enlarge the image with smooth transition
  transform: scale(1.1);
  transition: transform ease-in-out 0.5s;

}

.p4pLogoTop {
  width: 174px;
  height: 174px;
  background-image: url('../../assets/images/home/p4pLogo.png');
  background-position: center;
  background-size: contain;
  display: none;
  background-repeat: no-repeat;


  @media (max-width: 1150px) {
    display: block;
  }



}

.DNPlogo {
  width: 200px;
  height: 200px;
  background-image: url('../../assets/images/common/logo.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -20px;
  left: 20px;

  @media(max-width: 1150px) {
    display: none;
  }
}

.main {

  display: flex;
  flex-direction: column;
  // z-index: 1000;
  // position: absolute;
  justify-content: space-evenly;

  .blackMid {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100vw;
    background-color: #000;
    margin: auto;
    flex: 1;

    @media (min-width: 2000px) {
      justify-content: center;

      &>div:first-child {
        margin-right: 100px;
      }

      &>div:last-child {
        margin-left: 100px;
      }
    }

    @media (max-width: 1150px) {
      flex-direction: column;
      background-color: rgba($color: #000000, $alpha: 0.0);
      width: 100vw;
      margin-bottom: 0px;
    }
  }

  .topH {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin: auto;
    flex: 1;
    margin-bottom: 30px;

    @media (max-width: 1150px) {
      flex-direction: column;
      width: 100vw;
      margin-bottom: 0px;
      // margin-top: 55px;
    }
  }

  .bottomH {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100vw;
    margin: auto;
    margin-top: 30px;

    @media (max-width: 1150px) {
      flex-direction: column;
      margin-top: 0px;
    }
  }

}
.container {
	margin-bottom: 50px;
}


.header {
	margin: 0px 0 20px 0;
	h2 {
		font-family: 'Ubuntu', sans-serif;
	}
}

.headerImage {
	max-width: 100%;
	border-radius: 3px;
	overflow: hidden;
	position: relative;
	margin-bottom: 10px;

	img {
		max-width: 100%;
		height: auto;
	}

	div {
		position: absolute;
		top: 0; left: 0;
		background-color: rgba(#fff, .7);
		width: 100%; height: 100%;
	}

	h3 {
		position: absolute;
		top: 50%; left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		font-size: 50px;

		@media only screen and (max-width: 650px) {
			font-size: 25px;
		}
	}
}

.sectionHeader {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 16px;
	margin: 0px 0px 2px 2px;
	color: #2980b9;

	&:not(:nth-of-type(1)) {
		margin-top: 25px;
	}
}

form:global(.ui.form) {

	.section {
		// background-color: #f4f6fa;
		background-color: #fff;
		padding: 20px;
		border-radius: 3px;
		box-shadow: 0 0 2px rgba(#000, .2);
		// border: 1px solid #bababa;

		div:global(.field) {

			& > label {
				&:not(:global(.labelError)) {
					color: #2b3c51;
				}
				font-weight: 500;
				letter-spacing: 1px;
				font-family: 'Ubuntu', sans-serif;
				font-size: 14px;
			}

			div:global(.ui.input) {
				input {
					// box-shadow: 1px 1px 2px rgba(#000, .4);
					// border-radius: 2px;
					// background-color: #f4f8f7;
					// border: 1px solid #edeff3;
					border: 1px solid #e4eaef;

					&:focus {
						box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
							rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
						-webkit-transition: all 150ms ease;
						transition: all 150ms ease;
					}
				}
			}
		}
	}

}

.contract {
	height: 200px;
	overflow-y: scroll;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	// background-color: #f4f8f7;
	border: 1px solid #e4eaef;
	margin-bottom: 10px;
}

.formGroup {
	display: flex;
	label {
		flex: 1;
		&:not(:last-child) {
			margin-right: 8px;
		}
	}
}

.poweredBy {
	display: flex;
	justify-content: flex-end;

	img {
		height: 24px;
		width: auto;
	}
}

.memberContainer {
	margin-bottom: 10px;
}

.right {
	display: flex;
	justify-content: flex-end;
}

.finalPrice {
	margin: 20px 0;
	span:first-child {
		font-size: 30px;	
	}

	span:last-child {
		font-size: 30px;	
	}

}
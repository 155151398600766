.u-center-text {
	text-align: center !important;
}

.u-margin-bottom-small { margin-bottom: 2rem !important; }
.u-margin-bottom-medium { margin-bottom: 4rem !important; }
.u-margin-bottom-big { margin-bottom: 8rem !important; }

.u-margin-top-small { margin-top: 2rem !important; }
.u-margin-top-medium { margin-top: 4rem !important; }
.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; }


.u-align-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.u-align-middle {
	display: flex;
	align-items: center;
	justify-content: center;
}
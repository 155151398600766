.orderDetails {
	margin-top: 10px;
	background-color: #fff;
	padding: 20px;
	border-radius: 3px;
	box-shadow: 0 0 2px rgba(#000, .2);
	display: flex;
	flex-direction: column;

	h3 {
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.row {
		display: flex;
		margin: 3px 0;
		
		span:first-child {
			flex: 1;
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		background-color: #f2f2f2;
		margin: 3px auto;
	}

}
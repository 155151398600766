div.segment.stacked.store {
	width: 30rem;
	max-width: 30rem;
	// border: 1px $color-primary solid;
	padding: 3rem 1rem;
	margin: 2rem;
	text-align: center;
	justify-content: space-between;
	flex: 1 1 100%;
	display: flex;
	flex-direction: column;

	img {
		max-width: 100px;
		margin: 0 auto;
		text-align: center;

		&.imageLogo {
			max-width: 280px;
			width: 280px;
			height: 100px;
		}
	}

	h4 {
		font-size: 1.5rem;
		text-align: center;
		white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	}

	h2 {
		font-size: 2.5rem;
		text-align: center;
	}

	button {
		font-size: 1.4rem;
		text-align: center;
	}

	div:nth-child(1) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	div:nth-child(2) {
		display: flex;
		margin: 2rem 0;
		flex-direction: column;
		justify-content: flex-start;
		
	}

	div:nth-child(3) {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1;

		p {
			font-size: 1.5rem;
		}
	}

	.store__address {
		font-size: 1.4rem;

		p {
			margin: .3rem 0;
		}

		margin: .5rem 0;
	}

}

.promotion {

	&:not(:last-child) {
		border-bottom: 2px solid rgb(173, 173, 173);
		padding-bottom: 1rem;
		margin: 1rem 0rem;
	}

}
.purchase-complete {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 2rem;

	i {
		font-size: 5rem;
		color: #2ecc71;
	}

	h3 {
		font-size: 3rem;
	}

	p {

	}

	.receiptButton {
		font-size: 1.4rem;
	}
}
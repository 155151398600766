.partners {
	display: flex;
	flex-direction: column;

	.header {
		min-height: 70vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0rem 2rem;

		&__h1 {
			font-size: 5rem;
			font-weight: 400;
			padding-top: 3rem;
			padding-bottom: 5rem;

			@include respond(phone) {
				text-align: center;
			}
		}

		&__image {
			width: 70vw;

			@include respond(phone) {
				width: 100vw;
			}
		}

		&__paragraph {
			font-size: 3rem;
			max-width: 80vw;
			text-align: center;
			font-weight: 300;
			padding: 5rem 0rem;

			@include respond(phone) {
				font-size: 1.4rem;
				padding: 3rem 0rem;
			}
		}

	}

	.content:not(.nogo) {
		& > * {
			display: flex;
			justify-content: center;

			@include respond(phone) {
				flex-direction: column;
			}
		}
	}

	.content {
		background-color: $color-primary;
		background-image: url("../../assets/textures/diamonds.png");

		&__card {
			border: $partners-card-border;
			background-color: $color-textbox-fill;
			max-width: 500px;
			margin: 4rem;
			display: flex;
			flex-direction: column;

			@include respond(phone) {
				margin: 1rem;
			}

			&__header {
				padding: 1rem 3rem;
				&__text {
					font-size: 3rem;
					font-weight: 400;

					@include respond(phone) {
						text-align: center;
					}
				}
			}

			&__image {
				border-top: $partners-card-border;
				border-bottom: $partners-card-border;
				width: 100%;
			}

			&__content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1;
				

				&__paragraph {
					text-align: center;
					font-size: 1.8rem;
					padding: 2rem;
					font-weight: 300;

					@include respond(phone) {
						font-size: 1.4rem;
					}
				}

				&__buttons {
					display: flex;
					justify-content: flex-end;
					padding: 1rem;

					button {
						margin-right: 1rem;
						padding: 1rem 2rem;
					}
				}
			}

		}



	}

	.backoffice {
		background-color: $color-primary;
		background-image: url("../../assets/textures/diamonds.png");
		padding: 10rem 20rem;

		@include respond(phone) {
			padding: 0rem;
			height: 300px;
			display: flex;
			align-items: center;
			justify-content: center;
		}


		&__content {
			background-image: url("../../assets/images/partners/pic_backoffice_people.jpg");
			min-height: 300px;
			max-height: 320px;
			background-size: cover;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			justify-content: center;
			border: $color-primary 3px solid;

			@include respond(phone) {
				height: 200px;
				min-height: 200px;
				max-height: 200px;
				width: 100%;
				align-items: flex-end;
			}

			button {
				padding: 1.8rem 2.5rem;
				font-weight: 300;
				letter-spacing: .3rem;
				border: 2px solid black;
				border-radius: 1px;

				@include respond(phone) {
					margin-bottom: 1rem;
				}
			}

		}

	}

}


.a_image_1 {
	background-image: url(../../assets/images/affiliates/basic_networking.jpg);
}

.a_image_2 {
	background-image: url(../../assets/images/affiliates/premier_networking.jpg);
}

.a_image_3 {
	background-image: url(../../assets/images/affiliates/pic_basic_business_plan.jpg);
}

.a_image_4 {
	background-image: url(../../assets/images/affiliates/pic_premier_business_plan.jpg);
}
.dividerContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    .divider{
        width: 100%;
        height: 1px;
        background-color: #626262;
        margin: 10px 0px;
    }
}

.header {
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #2d3436;
	font-size: 2rem;
}

.subheader {
	font-size: 1.5rem;
	color: #2d3436;
	letter-spacing: 1px;
}

.seperator {
	border-bottom: 1px solid #ededed;
}

.checkbox:global(.ui.checkbox) {
	font-size: 10px;
	margin: 0 0 15px 0;

	label {
		margin-left: 0;
	}

}
div{
	.purchasePrice{
		margin-top:5px;
		font-weight: 300;
	}
}
.purchasePlanButton{
	margin: 15px auto;
	padding: 4rem 3rem;
	font-size: 1.8rem;
	background-color:  #8c7ae6;

	&:hover {
		background-color:  #9c88ff;
	}
}
.goToTestButton{
	margin: 15px auto;
	padding: 4rem 3rem;
	font-size: 1.8rem;
	background-color:  #ff4757;

	&:hover {
		background-color:  #ff6b81;
	}
}


.wizard {
	div:global(.ui.segment), div:global(.ui.segments), div:global(.segment) {
		font-size: 1.4rem;
	}

	.buttons {
		display: flex;
		align-items: center;
		&:first-child {
			flex: 1;
		}
	}
}
.container:global(.ui.segment){
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	max-width: 400px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	height: 800px;
	width: 400px;
	margin:40px;
	transition: all .15s ease-in-out;
	cursor: pointer;
	margin: 0 auto;

	@media only screen and (max-width: 550px) {
		width: 100%;
		height: unset;
	}

	&:hover { transform: scale(1.05); };

	ul{
		li{
			margin: 5px 20px;
		}
	}

	button{
		color: #9c88ff;
		border: 3px solid #9c88ff;
		padding:5px 10px;
		box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
		border-radius: 5px;
		transition: all .2s ease-in-out;
		margin:15px 0px;

		&:hover{
			background-color: #9c88ff;
			color: #fff;
		}
	}

	.planPic{
		height: 150px;
		width: auto;

		@media only screen and (max-width: 550px) {
			height: auto;
			width: 100%;
		}
	}
}

.error {
	color: #e74c3c;
	font-size: 24px;
	padding: 10px 4px;
}

.plansContainer:global(.ui.centered.container.stackable.two.column.grid) {
	@media only screen and (max-width: 1080px) {
		flex-direction: column;
		align-items: center;
	}
}
$color-primary: #626262;

$color-textbox-border: #a3a3a3;
$color-textbox-fill: #f5f5f5;

$color-background-secondary: #ebebeb;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$color-btn-text-hover: #fdcb6e;

// FONT SIZES
$default-font-size: 1.6rem;

$home-navbar-size: 60px;

$shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
$shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

$line: 1px solid $color-grey-light-2;

$partners-card-border: 3px solid $color-primary;

@font-face {
  font-family: "TimesNewRomanPSMT";
  src: url("../../assets/fonts/TimesNewRomanPSMT.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*
$breakpoint arg:
- phone
- portrait
- landscape
- wide
*/

@mixin respond($breakpoint) {
	@if $breakpoint == phone {
		@media only screen and (max-width: 37.5em) { @content };
	}
	@if $breakpoint == phoneHome {
		@media only screen and (max-width: 1210px) { @content };
	}
	@if $breakpoint == portrait {
		@media only screen and (max-width: 56.25em) { @content };
	}
	@if $breakpoint == landscape {
		@media only screen and (max-width: 75em) { @content };
	}
	@if $breakpoint == wide {
		@media only screen and (min-width: 112.5em) { @content };
	}
}
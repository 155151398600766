
$filter-menu-width: 250px;

$mobile-breakpoint: 780px;


.pageContainer {
	background-color: transparent;
	// padding: 20px;
	// max-width: 100vw;
	// overflow: hidden;

	h1 {
		font-size: 5rem;
		text-align: center;
	}

	:global(.rc-pagination.rc-pagination-simple) {
		display: flex;
		justify-content: center;
		align-items: center;

		:global(.rc-pagination-simple-pager) {
			display: flex;
			justify-content: center;
			align-items: center;
		}

	}
}

.searchContainer {
	display: flex;
	padding-bottom: 20px;
	background-color: transparent;
	overflow: hidden;
	max-width: 100vw;
}


.bigFilterMenu {
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	// margin-right: 20px;
	width: $filter-menu-width;
	transition: .2s margin-left;
	padding: 10px 0px 10px 20px;

	@media (max-width: $mobile-breakpoint) {
		margin-left: -$filter-menu-width;
		margin-right: 0;
		padding: 20px;
		// width: 0;
	}

	&.menuOpen {
		@media (max-width: $mobile-breakpoint) {
			margin-left: 0;	
		}
	}

	.filtersHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h3 {
			font-size: 16px;
			text-transform: uppercase;
			letter-spacing: 2px;
			margin: 0;
		}

		span {
			cursor: pointer;
			transition: .1s all;
			color: rgba(148, 148, 148, 0.577);
		}

		span:hover {
			color: #74b9ff;
		}
	}

	.filtersHR {
		background-color: #ced9ed;
		width: 100%;
		height: 1px;
		margin-top: 10px;
		border-radius: 2px;
		align-self: center;
	}
}

.searchResultsContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 10px;
	max-width: 100vw;
	// overflow: hidden;

	&:global(.ui.segment) {
		margin: 20px;
		margin-top: 0;
		box-shadow: none;
		border-radius: 2px;
	}

	.resultsTop {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 450px) {
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
		}

		h4 {
			font-size: 20px;
			margin: 0;
			text-align: left;
		}

		.resultsSortBy {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@media (max-width: 450px) {
				margin-top: 15px;
				justify-content: center;
			}

			h5 {
				margin: 0;
				padding: 0;
				font-size: 14px;
				margin-right: 10px;
				color: #617082;
			}
		}

		:global(.field) {
			flex: 1;
			max-width: 200px;
		}
	}

	.results {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: flex-end;
		position: relative;
	}
}

.searchInput {
	margin-bottom: 20px;
	padding: 20px 20px 0 20px;

	:global(.ui.big.action.input) {

		box-shadow: 0 5px 6px rgba(0,0,0,0.06), 0 6px 6px rgba(0,0,0,0.06);

		:global(.ui.blue.button) {
			font-size: 16px;
		}
	}
}

.filterSection {
	margin: 15px 0;


	h4 {
		color: rgba(black, .8);
		font-size: 14px;
		letter-spacing: 1px;
	}


	:global(.checkbox) {
		:global(.ui.checkbox) {

			font-size: 16px;

			input:checked ~ label::before {
				background-color: #2ecc71 !important;
			}

			label {
				height: 30px;

				&::after {
					color: #fff !important;
					position: absolute;
					font-size: 12px;
				}
			}
		}
	}


}

.noResults {
	text-align: center;

	h3 {
		font-size: 30px;
	}

	p {
		font-size: 20px;
	}
}

.filterCol {
	position: relative;
	flex: 1;
	display: flex;
	// justify-content: space-between;
	flex-direction: column;
}

.applyButton {
	display: block;
	// position: -webkit-sticky;
	// position: sticky;
	// bottom: 20px;
}


.mobileShowButtonContainer {
	position: fixed;
	bottom: 50%;
	transform: translateY(50%);
	left: 0;
	width: 30px;
	padding: 10px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	background-color: #2980b9;
	color: #fff;
	box-shadow: 0 2px 10px -2px rgba(0,0,0,.8);
	z-index: 103;
	cursor: pointer;
	transition: all .2s;

	&:hover {
		width: 40px;

		p {
			padding-left: 10px;
		}
	}

	p {
		text-align: center;
		margin: 0;
		line-height: 1em;
		transition: all .2s;
	}

	&.menuOpen {
		display: none;
	}

	@media (min-width: $mobile-breakpoint) {
		display: none;
	}
}


.locationBox {
	padding: 5px;
	border-radius: 2px;
	// background-color: rgba(0,0,0,.05);
	border: 1px solid #ededed;
}

.locationBox2 {
	margin-top: 10px;
	padding: 5px;

	h5 {
		color: rgba(black, .8);
		font-size: 13px;
		margin-bottom: 2px;
	}
}

.downloadResults {
	text-align: left;
	color: #0f84e3;
	font-size: 12px;
	cursor: pointer;

	&.downloadResultsLoading {
		cursor: default;
		color: grey;
		pointer-events: none;
	}

	&:hover:not(.downloadResultsLoading) {
		color: #74b9ff;
	}
}
.button-normal {
	background-color: #2185d0;
	color: #fff;
	text-shadow: none;
	background-image: none;
	font-size: 1.4rem;
	cursor: pointer;
	min-height: 1.4rem;
	outline: none;
	border: none;
	vertical-align: baseline;
	font-family: inherit;
	margin-right: 0.35rem;
	padding: 0 1.4rem;
	line-height: 4rem;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: .5px;
	text-align: center;
	border-radius: 4px;
	transition: all .15s ease;
	box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.button-normal.no-line-height {
	line-height: initial;
}

.button-normal:hover {
	box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
	transform: translateY(-1px);
	background-color: #5fabe6;
}

.button-normal.outline {
	color: $color-primary;
	background-color: transparent;
	border: 2px solid #2185d0;
	letter-spacing: initial;
	box-shadow: none;
	
	&:hover {
		background-color: #2185d0;
	}

}

.button-normal:focus {
	background-color: #5fabe6;
}

.button-normal.green {
	background-color: #2ed573;
}

.button-normal.green:hover {
	background-color: #7bed9f;
}

.button-normal.green.outline {
	color: $color-primary;
	background-color: transparent;
	border: 2px solid #2ed573;
	letter-spacing: initial;
	box-shadow: none;
	
	&:hover {
		background-color: #2ed573;
	}

}

.button-normal.lightred {
	background-color: #ff4757;
}

.button-normal.lightred:hover {
	background-color: #ff6b81;
}

.button-normal.lightred.outline {
	color: $color-primary;
	background-color: transparent;
	border: 2px solid #ff6b81;
	letter-spacing: initial;
	box-shadow: none;
	
	&:hover {
		background-color: #ff6b81;
	}

}

.button-normal.red {
	background-color: #c0392b;
}

.button-normal.red:hover {
	background-color: #e74c3c;
}

.button-normal.lightblue {
	background-color: #1e90ff;
}

.button-normal.lightblue:hover {
	background-color: #70a1ff;
}

.button-normal.lightblue.outline {
	color: $color-primary;
	background-color: transparent;
	border: 2px solid #70a1ff;
	letter-spacing: initial;
	box-shadow: none;
	
	&:hover {
		background-color: #70a1ff;
	}

}

.button-normal.verydarkpurple {
	background-color: #130f40;
}

.button-normal.verydarkpurple:hover {
	background-color: #30336b;
}

.button-normal.verydarkpurple.outline {
	color: $color-primary;
	background-color: transparent;
	border: 2px solid #30336b;
	letter-spacing: initial;
	box-shadow: none;
	
	&:hover {
		background-color: #30336b;
	}

}

.button-normal.purple {
	background-color: #8c7ae6;
}

.button-normal.purple:hover {
	background-color: #9c88ff;
}

.button-normal.purple.outline {
	color: $color-primary;
	background-color: transparent;
	border: 2px solid #9c88ff;
	letter-spacing: initial;
	box-shadow: none;
	
	&:hover {
		background-color: #9c88ff;
	}

}

.button-normal-alt {
	background-color: #fff;
	color: #2185d0;
	box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
	margin: 1rem;


	&:hover {
		color: #5fabe6;
		background-color: #fff;
		box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
		transform: translateY(-1px);
	}

	&:focus {
		color: #5fabe6;
		background-color: #fff;
	}
}


.ui.button:disabled {
	cursor: not-allowed !important;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');

@import "abstract/functions";
@import "abstract/mixins";
@import "abstract/variables";

@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";


// Components
@import "components/additionalFamilyMembers";
@import "components/addressValidation";
@import "components/becomeAffiliate";
@import "components/btn";
@import "components/button-normal";
@import "components/buyCard";
@import "components/checkbox";
@import "components/contract";
@import "components/footer";
@import "components/forgotPassword";
@import "components/form";
@import "components/iconButton";
@import "components/loginOrContinue";
@import "components/m2";
@import "components/loginRegisterModal";
@import "components/message";
@import "components/modal-login";
@import "components/modal";
// @import "components/navbar";
// @import "components/navbar2";
@import "components/popup";
@import 'components/seminar';
@import "components/sigCanvas";
@import "components/signupBusiness";
@import "components/store";
@import "components/stripe";
@import "components/w9";

// Layout

// Pages
@import "pages/checkmembership";
@import "pages/contact";
@import "pages/faqs";
@import "pages/home";
@import "pages/legal";
@import "pages/loyaltyaffiliate";
@import "pages/partners-sub";
@import "pages/partners";
@import "pages/plans";
@import "pages/pricing";
@import "pages/searchpage";


// Hamburger Icon
@import "components/hamburger";



div.error.field {
	span.error {
		color: #d63031;
		font-size: 13px;
	}
}


.w23 {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}


.header {
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #2d3436;
	font-size: 2rem;
}

.subheader {
	font-size: 1.5rem;
	color: #2d3436;
	letter-spacing: 1px;
}

.ui.checkbox .box, .ui.checkbox label {
	font-size: 1.5rem;
}

.containerized {
	margin-top: 10px;
	background-color: #fff;
	padding: 20px;
	border-radius: 3px;
	box-shadow: 0 0 2px rgba(#000, .2);
}

span.success {
	color: #27ae60;
}

#y_video {
	max-width: 100%;
}

#webinar {
	height: 100%;
	width: 100%;
	min-height: 300px;
	// min-width: 300px;
}

.webinarContainer {
	flex: 1;
	padding: 5rem 10px;
	padding-top: 10px;
}

.videoContainer {
	width: 100%;
	height: 100%;
}

.video {
	height: 100%;
	width: 100%;
	min-height: 300px;
}

.h-move-up {
	bottom: 40px;
}

div.ql-editor:not(.no-con) {
	max-height: 200px;
	min-height: 150px;
}

div.quill.errored {
	.ql-container.ql-snow, .ql-toolbar.ql-snow {
		border-color: #c0392b;
	}
}

div.quill {
	background-color: #fff;
	.ql-container.ql-snow {
		border-bottom-left-radius: .28571429rem;
		border-bottom-right-radius: .28571429rem;
	}
	.ql-toolbar.ql-snow {
		border-top-left-radius: .28571429rem;
		border-top-right-radius: .28571429rem;
	}
}
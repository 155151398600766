.w9 {
	padding: 1rem;

	ol {
		margin-left: 3rem;
	}

	.checkbox {
		padding: 1rem;
		padding-bottom: 0rem;
	}

	.checkbox:hover {
		background-color: #eee;
	}

	.us-person {
		display: flex;
		flex-direction: column;
		padding: .5rem;

		label {
			input {
				padding: 1rem;
			}

			p {
				padding-left: 1rem;
				display: inline-block;
			}
		}
	}
}
.loginHeader {
	text-align: center;
	font-size: 1.8rem;
	margin: 1rem 0 0rem 0;
	text-transform: uppercase;
	color: #2c3e50;
	letter-spacing: 2px;

	&.registerHeader {
		margin: 2rem 0;
	}
}

.loginModalSection {
	// border: 1px solid #ededed;
}
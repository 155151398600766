.search {
	h1 {
		font-size: 5rem;
		text-align: center;
	}

	form {
		padding-bottom: 2rem;

		button {
			align-self: flex-end;
		}

		.ui .field > input {
			font-size: initial;
			padding: .95rem 1.4rem;
			border: 1px solid rgba(34, 36, 38, 0.15);
		}
	}

	&__stores {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: flex-end;
		position: relative;

		.ui.segment {
			margin: 1.5rem;
		}
	}

	&__container {
		margin: 1rem;
		margin-bottom: 2rem;
	}
}
.container {
	flex: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	// align-items: center;

	h4 {
		font-size: 25px;
		text-align: center;
		margin: 20px 10px 50px 10px;
		text-transform: uppercase;
		letter-spacing: 2px;
		color: #000;
	}

	.box {
		display: flex;
		flex-direction: column;
		max-width: 500px;
		flex: 1;
		padding: 10px;
		// margin: auto;
	}
}
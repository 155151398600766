.partners-more {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 1rem;

		&__h1 {
			font-size: 5rem;
			font-weight: 400;
			padding-top: 3rem;
			padding-bottom: 1rem;

			@include respond(phone) {
				font-size: 3rem;
			}
		}

		&__image {
			width: 70vw;
			min-width: 60rem;

			@include respond(phone) {
				width: 100vw;
				min-width: 100vw;
			}
		}

		&__image--bordered {
			border: $color-primary 3px solid;
		}

		&__paragraph {
			font-size: 2.5rem;
			max-width: 80vw;
			text-align: center;
			font-weight: 300;
			padding: 5rem 0rem;

			@include respond(phone) {
				font-size: 1.5rem;
				padding: 2rem 0rem;
				text-align: justify;
			}
		}

	}

	&__content {
		background-color: $color-primary;
		background-image: url("../../assets/textures/diamonds.png");
		padding: 2rem 4rem;
		display: flex;
		align-items: center;
		width: 100%;
		align-content: center;
		justify-content: center;

		@include respond(phone) {
			flex-direction: column;
		}

		&__video {
			margin: 3rem;
			height: 50vh;
			flex: 0 0 40%;
			background-color: $color-grey-light-1;

			@include respond(phone) {
				margin: 0;
				height: auto;
				width: 100%;
				flex: 0;
			}
		}

		&__paragraph {
			color: $color-white;
			padding: 3rem;
			font-size: 2.4rem;
			font-weight: 300;

			@include respond(phone) {
				font-size: 1.5rem;
				padding: 1rem;
			}
		}

		&__image {
			margin: 3rem;
			max-height: 50vh;

			@include respond(phone) {
				width: 100vw;
			}
		}
	}

	&-button {
		margin: 2rem;
		padding: 2rem 3rem;
		font-size: 1.7rem;
		font-weight: 300;

		@include respond(phone) {
			font-size: 1.5rem;
			padding: 1.8rem 2.9rem;
		}
	}

}

.pricing__scrollbox {
	@include respond(phone) {
		max-height: 30rem;
		text-align: justify;
	}
}

.affiliates__content {
	padding: 1rem;
}
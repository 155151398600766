.contactpage {
	display: flex;
	flex-direction: column;
	align-items: center;

	.contactpage__header h1.header__h1 {
		font-size: 5rem;
		text-align: center;
	}

	&__header {
		img {
			@include respond(phone) {
				width: 100vw;
				margin-top: 1.3rem;
			}
		}
	}

	&__info {
		margin-top: 3rem;
		display: flex;
		justify-content: center;
		align-self: stretch;

		@include respond(phone) {
			flex-direction: column;
		}

		&__box {
			border: 2px solid $color-primary;
			padding: 1rem 2rem;
			max-width: 500px;

			@include respond(phone) {
				border: none;
				text-align: center;
			}

			h2 {
				font-size: 2.7rem;
			}

			p {
				font-size: 1.9rem;
			}
		}

		&__box:not(:last-child) {
			margin-right: 5rem;
			text-align: center;

			@include respond(phone) {
				margin-right: 0;
			}

			h2 {
				margin-bottom: 3rem;
			}
		}

	}

	&__form {
		margin-top: 3rem;
		margin-bottom: 3rem;
		max-width: 700px;
		width: 100%;
		padding: 1rem;
		min-height: 433px;
		h3 {
			font-size: 3.5rem;
			@include respond(phone) {
				text-align: center;
			}
		}

	}

	.purchase-complete {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		i {
			font-size: 9rem;
		}

		h4 {
			font-size: 4rem;
		}

		padding: 1rem;
		min-height: 433px;
	}
}

main {
	flex: 1;
	z-index: 1;
	position: relative;
	background-color: white;
	border-bottom: 2px solid $color-primary;

	// @include respond(phone) {
	// 	margin-bottom: 675px;
	// }
}


.scrollbox {
	overflow: auto;
	max-width: 100%;
	max-height: 40rem;
	border: 2px #eee solid;
	padding: 1rem;
}

.video {
	width: 100%;
	height: 100%;
}
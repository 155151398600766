
.youtubeContainer{
	box-shadow: 0px 0px 10px black;
	transition: all .2s ease-in-out;

	// &:hover{
	// 	transform: scale(1.1);
	// }
}

.youtubeDiv {
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

}
.container {
	padding: 15px 20px;
	background-color: #d63031;
	letter-spacing: 1.2px;

	p {
		color: #ecf0f1;
		text-transform: uppercase;

		a {
			text-transform: lowercase;
			letter-spacing: 2px;
			&:link,
			&:visited {
				color: #ecf0f1;
			}

			&:hover,
			&:active {
				color: #bdc3c7;
			}

		}
	}
}
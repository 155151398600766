.home {
	position: relative;
	overflow: hidden;
  
	main {
	  padding-bottom: 0;
	}
  
	&__header {
	  width: 100vw;
	  min-height: calc(100vh - #{$home-navbar-size});
	  background-image: url('../../assets/images/home/pic_home_background.jpg');
	  background-repeat: no-repeat;
	  background-size: cover;
	  background-position: center center;
	  overflow: hidden;
	  
  
	  display: flex;
	  flex-direction: column;
	  justify-content: space-between;
	  align-items: flex-end;
		position: relative;

		@media(max-width: 1150px){
			padding-bottom: 150px;
			// height: calc(160vh - #{$home-navbar-size});
		}
	
		
		&__title {
			width: 100%;
			text-align: center;
			display: flex;
			justify-content: center;
			h3 {
				max-width: 80vw;
				color: #000;
				letter-spacing: 2px;
				font-size: 35px;
				font-weight: 400;
			}
		}
  
	  &__buttons {
		display: flex;
		justify-content: flex-end;
		margin: 20px 35px 20px 20px;
  
		&--1 {
		  padding: 10px 20px;
		}
  
		&--2 {
		  margin-left: 20px;
		  padding: 10px;
		}
	  }
  
	  &__button--special {
			margin: 20px 35px 20px 20px;
			max-width: 20rem;
			transition: all 200s;

			@media(max-width: 1150px){
				max-width: 30%;
				height: auto;
				position: absolute;
				bottom: 0;
				right: -12px;
			}
	  }

	  &__button--specialLeft {
		margin: 20px 35px 20px 20px;
		max-width: 20rem;
		position: absolute;
		bottom: 0;
		left: 0;

		@media(max-width: 1150px){
			width: 30%;
			height: auto;
		}
	  }

      &__text--specialLeft {
		margin: 20px 35px 20px 20px;
		max-width: 40rem;
		position: absolute;
		bottom: 0;
		left: 0;
        color: #fff;
        text-align: center;
        font-weight: 600;
        font-size: 20px;

		@media(max-width: 1150px){
			width: 40%;
			height: auto;
            font-size: 14px;
		}
	  }
  
	  &__logo {
		align-self: center;
		display: flex;
		font-size: 3rem;
		font-weight: 400;
		text-align: center;
		text-transform: uppercase;
		line-height: 5rem;
		align-items: center;
		margin-bottom: 10rem;
		position: relative;
		justify-content: center;
  
		h2 {
		  font-size: 3rem !important;
		}
  
		// *this was for the words that
		// *used to be next to (left & right) of the center logo
		// *i liked them but i am afriad they were killed off
		// *so i decided to save the code just incase theyre resurrected
		// * F
  
		&__left {
		  right: 200px;
		  position: absolute;
			font-weight: 300;
		  animation: header-enter-left 2s cubic-bezier(0.19, 1, 0.22, 1);
		  margin: 0 !important;
		  color: black;
		  @include respond(phoneHome) {
				display: none;
		  }
		}
  
		&__right {
		  left: 200px;
		  position: absolute;
		  font-weight: inherit;
		  animation: header-enter-right 2s cubic-bezier(0.71, 0.16, 0.19, 1.01);
		  margin: 0 !important;
		  color: black;
		  @include respond(phoneHome) {
			display: none;
		  }
		}
  
		&__container {
		  margin: 0px auto auto auto;
		  height: 35vh;
		  width: 70vw;
		  border-radius: 0%;
		  position: relative;
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		

		  div {
			  h2 {
				  color:#000;
				  text-align: center;
					font-size: 35px;
					font-weight: 400;
				  width: 300px;
				  height: 100px;

				  @media only screen and (max-width: 1640px) {
					font-size: 30px;
					width: 250px;
				  }
				  @media only screen and (max-width: 1500px) {
					font-size: 25px;
					width: 200px;
				  }
				  @media only screen and (max-width: 1330px) {
					font-size: 22px;
					width: 175px;
				  }
				  @media only screen and (max-width: 1110px) {
					display: none;
				  }

			  }
		  }
		  
		  button {
			  position: absolute;
			  top: 50%;
			  left: 50%;
			  margin: auto;
			  transform: translate(-50%, -50%);
			  -ms-transform: translate(-50%, -50%);
			  background-color: #555;
			  color: white;
			  margin: 17.5vh 15vw ;
			  font-size: 16px;
			  padding: 30px 60px;
			  border: none;
			  cursor: pointer;
			  border-radius: 5px;
			  opacity: 0;
			  z-index: 10000;
			  
			}
			
			button:hover {
			  background-color: black;
			  opacity: 1;
			}
  
  
  
		}
		&__image {
		  margin: auto;
		  height: 28vh;
		  max-width: 80vw;
		  border-radius: 0%;
		  transition: all 0.2s ease-in-out;

		  @media only screen and (max-width: 1550px) {
			height: 25vh;
			max-width: 70vw;
		  }
  
  
		  &:hover {
			transform: scale(1.05);
		  //   filter: grayscale(0%) brightness(50%);
		  }
		}
  
	
  
		  @include respond(phone) {
			width: 80vw;
		  }
		
	  }
	}
  
	&__nav {
	  background-color: #2f3640;
	  height: $home-navbar-size;
	  display: flex;
	  flex-direction: column;
  
	  @media (max-width: 900px) {
			display: none;
	  }
  
	  &-list {
			display: flex;
			justify-content: space-around;
			height: 100%;
			align-items: center;
			font-size: 2.5rem;
			font-weight: 300;
			text-transform: uppercase;
			color: $color-white;
			list-style: none;
  
		@media (max-width: 1145px) {
		  font-size: 2rem;
		}
  
		a {
		  text-decoration: none;
		  color: $color-white;
		  transition: all 0.2s;
		}
  
		a:hover {
		  color: #ffa502;
		}
	  }
	}
  
	&__section {
	  display: flex;
	  overflow: hidden;
	  width: 100vw;
	  //max-height: 50rem;
	  border-top: $line;
	  min-height: 50vh;

	  &:first-child() {
		width: 40%;
	  }
  
	  &:nth-last-child() {
		width: 30%;
	  }
	}
	
	#widget2, #widget4 {
		max-width: 100%;
	}
	
	&__section > *:first-child {
		width: 50%;
		
		@include respond(phoneHome) {
			width: 100%;
	  }
	}
  
	&__section > *:last-child {
		width: 50%;
		
		@include respond(phoneHome) {
			width: 100%;
	  }
	}
  
	&__section--bus {
	  display: flex;
	  overflow: visible;
	  align-items: center;

  
	  @include respond(phoneHome) {
			flex-wrap: wrap;
			width: 100%;
	  }
	}
	&__section--savings {
	  display: flex;
	  overflow: visible;

  
	  @include respond(phoneHome) {
		flex-wrap: wrap;
		width: 100%;
	  }
	}
  
	.business__img {
	  // width: 5%;
	  background-image: url(../../assets/images/home/savings.jpg);
	  background-size: cover;
  
	  @include respond(phone) {
		width: 100%;
		height: 40rem;
	  }
	}
  

	.savings__img {
		// width: 5%;
		background-image: url(../../assets/images/home/pic_home_map_small.jpg);
		background-size: cover;
	
		@include respond(phoneHome) {
		  width: 100%;
		  height: 40rem;
		}
	  }
	
	.savings__content {
	  display: flex;
  
	  @include respond(phoneHome) {
		width: 100%;
		flex-wrap: wrap;
	  }
  
	  &__description {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 50%;
			padding: 10px 2rem;
			padding-left: 4rem;
  
		@include respond(phoneHome) {
		  width: 100%;
		}
  
		img {
		  width: 25rem;
		}
  
		h3 {
		  font-size: 3rem;
		  text-transform: uppercase;
		}
  
		p {
		  font-size: 2rem;
		  font-weight: 300;
		  padding: 2rem;
  
		  @include respond(phoneHome) {
			text-align: center;
		  }
		}
  
		&__effect {
		  width: 100%;
		  height: 5rem;
		  overflow: hidden;
		  flex: 1;
  
		  div {
			background-color: $color-grey-light-2;
			width: 100%;
			height: 100%;
			padding: 20px;
		  }
		}
  
		button {
		  margin: 2rem 0;
		}
	  }
  
	  &__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 50%;
		padding: 1rem 2rem;
		padding-right: 4rem;
  
		@include respond(phoneHome) {
		  width: 100%;
		  padding: 1rem 2rem;
		}
  
		&__header {
		  display: flex;
		  flex-direction: column;
		  align-items: center;
		  justify-content: flex-start;
  
		  h3 {
			font-size: 3rem;
			text-transform: uppercase;
		  }
  
		  img {
			margin-top: 1rem;
			width: 6rem;
		  }
		}
  
		form {
		  align-self: flex-end;
		  display: flex;
		  flex-direction: column;
		  width: 100%;
  
		  button {
			align-self: center;
			padding: 1rem 2rem;
		  }
		}
	  }
	}
  
	&__section--business {
	  display: flex;
	  align-items: center;
  
	  @include respond(phoneHome) {
		flex-wrap: wrap-reverse;
		width: 100%;
	  }
	}
	&__section--influence {
	  display: flex;
  
	  @include respond(phoneHome) {
		flex-wrap: wrap-reverse;
		width: 100%;
	  }
	}
  
	.business__content {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  text-align: center;
	  padding: 20px;
  
	  @include respond(phoneHome) {
		width: 100%;
	  }
  
	  img {
		width: 10rem;
	  }
  
	  h3 {
		font-size: 3rem;
		text-transform: uppercase;
	  }
  
	  p {
		font-size: 2rem;
		font-weight: 300;
		padding: 2rem;
		max-width: 450px;
	  }
	}
  
	.business__effect {
	  background-image: url(../../assets/images/home/pic_home_influencers.jpg);
	  background-size: cover;
	  background-position: top center;
  
	  @include respond(phoneHome) {
		width: 100%;
		height: 40rem;
	  }
	}
	.educators__effect {
	  background-image: url(../../assets/images/home/pic_home_educator_wide.jpg);
	  background-size: cover;
	  background-position: top center;
  
	  @include respond(phoneHome) {
		width: 100%;
		height: 40rem;
	  }
	}
  
	&__section--partners {
	  display: flex;
  
	  @include respond(phoneHome) {
		flex-wrap: wrap;
		width: 100%;
	  }
	}
  
	.partners__img {
	  background-image: url(../../assets/images/home/partners.jpg);
	  background-size: cover;
	  @include respond(phoneHome) {
		width: 100%;
		height: 40rem;
	  }
	}
  
	.partners__content {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  padding: 2rem;
	  justify-content: center;
  
	  @include respond(phoneHome) {
		width: 100%;
		text-align: center;
	  }
  
	  img {
		width: 10rem;
	  }
  
	  h3 {
		font-size: 3rem;
		text-transform: uppercase;
		text-align: center;
	  }
  
	  p {
		font-size: 2rem;
		font-weight: 300;
		padding: 2rem;
		text-align: center;
		max-width: 450px;
	  }
	}
  
	.social-media {
	  display: flex;
	  flex-direction: column;
	  background-color: $color-background-secondary;
	  padding: 3rem 1rem;
	  padding-bottom: 4rem;
  
	  h3 {
			text-align: center;
			font-size: 4.3rem;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 2px;
	  }
  
	  &__container {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			padding-top: 3rem;
			max-height: 500px;
  
			@include respond(phoneHome) {
				flex-wrap: wrap;
				width: 100%;
				max-height: none;
			}
  
			&__api {
				text-align: center;
				min-width: 400px;
		
				@include respond(phoneHome) {
				min-width: none;
				padding: 2rem;
				max-width: 80%;
				}
			}
  
			.api__facebook {
				width: 400px;
			}
  
			.api__youtube {
			}
  
			.api__twitter {
				@include respond(phoneHome) {
					width: 80%;
				}
			}
	  }
	}
  
	&__newsletter {
		background-color: $color-primary;
		color: $color-white;
		padding: 0.2rem 1rem;
		padding-bottom: 1rem;
		
		label {
			font-weight: 300;
		}
	
		&__align {
			width: 100%;
			display: flex;
			align-items: center;

			.ui.fluid.input.input--alt.input-newsletter {
				max-width: 500px;
				flex: 1;
			}
		
			button {
				margin-left: 2rem;
			}
		}
	}
}
  
  @keyframes header-enter-left {
		0% {
			margin-right: 50vh;
			opacity: 0;
		}
  
		100% {
			margin-right: 0;
			opacity: 1;
		}
  }
  
  @keyframes header-enter-right {
		0% {
			margin-left: 50vh;
			opacity: 0;
		}
  
		100% {
			margin-left: 0;
			opacity: 1;
		}
  }
  
  .Select-menu-outer {
		position: absolute !important;
		z-index: 100000 !important;
  }
  
.container {
	background-color: #e3e8ee;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;

	padding-top: 50px;

	a  > img {
		width: 200px;
		height: auto;
	}

	h3 {
		font-size: 20px;
	}

	.informationContainer {
		margin-top: 10px;
		padding: 20px;
		color: #2a2f45;
		// border: 1px solid black;
		border-radius: 3px;
		background-color: #fff;
		box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);

		&.inputContainer {
			box-shadow: none;
			padding: 13px;
			border-radius: 1px;

			button {
				font-size: 13px;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}
	}

}

.membershipID {
	text-align: center;
}

.status {
	text-align: center;
	margin: 0;
	font-size: 20px;
	text-transform: uppercase;
}

.footerContainer {
	max-width: 100vw;
	min-height: 300px;
	background-color: #1e252b;
	position: relative;
	z-index: 1;
	display: flex;
	flex-wrap: wrap;

	@media only screen and (max-width: 860px) {
		flex-direction: column;
	}
}

.footerInfoContainer {
	color: #fff;
	letter-spacing: 1px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 30px;
	flex: 1;

	h3 {
		font-size: 32px;
		margin: 0;
		margin-bottom: 10px;
	}

	h4 {
		font-size: 16px;
		margin: 0;
		font-weight: 300;
	}

	@media only screen and (max-width: 860px) {
		align-items: center;
	}
}

.socialMediaContainer {
	flex: 2;
	border-left: 1px solid #333e48;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	@media only screen and (max-width: 860px) {
		flex-direction: column;
		justify-content: center;
	}

	.socialStoreContainer {
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
		padding: 20px;

		div {
			width: 150px;

			&.apple {
				img {
					margin: 7%;
					width: 84%;
				}
			}

			img {
				max-width: 100%;
				height: auto;
				display: block;
			}
		}

		h4 {
			color: #fff;
			letter-spacing: 1px;
			font-size: 20px;
		}

		div {
			flex: 1;
			margin: 0 auto;
			margin: 10px;
		}

		@media only screen and (max-width: 1420px) {
			flex-direction: row;
			justify-content: center;

			div {
				margin: 3px;
			}	
		}
	}

	.socialCol {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
	}
}

.footerCopyrightSection {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;

	border-left: 1px solid #333e48;
	padding-left: 100px;

	color: #fff;

	p {
		font-size: 16px;
	}


	.footerSitemapTrigger {
		color: #fff;
		display: flex;
		align-items: center;

		h5 {
			display: inline-block;
			margin: 0;
			margin-left: 10px;
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: 3px;
		}

	}

	@media only screen and (max-width: 1350px) {
		flex-direction: column-reverse;
		justify-content: space-between;

		text-align: center;
		margin-right: 10px;
		padding-left: 10px;

		.footerSitemapTrigger {
			flex: 1;
			justify-content: center;
		}
		
		p {
			margin-top: 10px;
			font-size: 14px;
		}
	}

}

$sitemap-height: 300px;

.footerWrapper {
	position: relative;
	padding-bottom: 0;

	&.sitemapClosed {
		padding-bottom: $sitemap-height;

		@media only screen and (max-width: 500px) {
			padding-bottom: $sitemap-height + 100px;
		}
	}

	transition: all .3s;
}

.sitemapContainer {
	position: absolute;
	width: 100vw;
	min-height: $sitemap-height;
	background-color: #333e48;
	bottom: 0;
	display: flex;

	@media only screen and (max-width: 500px) {
		min-height: $sitemap-height + 100px;
	}


	.sitemapLinks {

		position: absolute;
		display: flex;
		left: 5%;
		right: 5%;
		top: 50%;
		transform: translateY(-50%);
		max-width: 100vw;
		flex-wrap: wrap;
		align-content: center;

		max-height: 100%;
		overflow: hidden;

		.sitemapCol {
			display: flex;
			flex-direction: column;
			margin: 10px;

			&:not(:last-child) {
				margin-right: 40px;
			}

			h4 {
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 2px;
				font-size: 20px;
				font-weight: 300;

				@media only screen and (max-width: 880px) {
					font-size: 18px;
				}
			}

			.linksContainer {
				flex: 1;
				display: flex;
				flex-direction: column;

				a {
					font-size: 12px;
					letter-spacing: 2px;
					cursor: pointer;
					text-transform: uppercase;
					color: #fff;
					transition: .2s color;
					
					@media only screen and (max-width: 880px) {
						font-size: 10px;
					}

					&:not(:last-child) {
						margin-bottom: 6px;
					}
				}

				a:hover {
					color: #60a3bc !important;
				}
			}

		}
	}

}
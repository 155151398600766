.checkmembership {

	&__jumbotron {
		border-radius: 0 !important;
		min-height: 150px;
		background: #2d3436 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #74b9ff !important;
	}

	&__id {
		border-top: 2px solid rgba(0,0,0,0.12);
		padding-top: 20px;
		text-align: center;
		font-size: 30px;
	}

	&__found {
		font-size: 20px;
		color: #74b9ff;
	}

	&__expires {
		color: #e17055;
		font-size: 18px;
	}

	&__expireslater {
		color: #00b894;
		font-size: 18px;
	}

	&__plan {
		margin: 0;
		font-size: 20px;

		span {
			font-weight: 400;
		}
	}

	&__holder {
		font-size: 20px;
		margin: 0;
	}

	&__holdername {
		font-size: 20px;
		margin-left: 3px;
	}

	&__additional {
		margin-top: 15px !important;
		font-size: 20px;
	}

	&__fname {
		font-size: 20px;
	}

	&__notfound {
		font-size: 20px;
		color: #d63031;
	}

	&__box {
		background-color: #fff;
		padding: 20px 60px;
		min-width: 400px;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		text-align: center;
		border-radius: 2px;
	}
}
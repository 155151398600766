.message {
	position: relative;
	min-height: 1.5rem;
	background: #f8f8f9;
	padding: 1.4rem 2.1rem;
	box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 0 0 0 transparent;
	border-radius: .4rem;
	transition: opacity .1s ease,
				color .1s ease,
				background .1s ease,
				box-shadow .1s ease;
	margin-bottom: .5rem;
	color: rgba(0,0,0,.87);

	h3 {
		font-size: 1.6rem;
		color: rgba(0,0,0,.87);
		font-weight: 400;
		line-height: 2rem;
		text-transform: uppercase;
	}

	h3 + p {
		margin-top: .35rem;
	}

	p {
		font-size: 1.4rem;
	}
}

div.message.message__error {
	color: #9f3a38;
	background: #fff6f6;
	box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;

	h3 {
		color: #912d2b;
	}

}
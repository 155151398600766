.form {

	div:global(.ui.segment) {
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
	}

	.contract {
		// margin: 0;
		height: 200px;
		overflow-y: scroll;
		width: 100%;
		padding: 10px 30px;
		border-radius: 5px;
		// background-color: #f4f8f7;
		border: 1px solid #e4eaef;
		margin-bottom: 10px;
	}
}

.box {
	margin-top: 10px;
	background-color: #fff;
	padding: 20px;
	border-radius: 3px;
	box-shadow: 0 0 2px rgba(#000, .2);
	display: flex;
	flex-direction: column;
}

.wizard {
	div:global(.ui.segment), div:global(.ui.segments), div:global(.segment) {
		font-size: 1.4rem;
	}

	.buttons {
		display: flex;
		align-items: center;
		&:first-child {
			flex: 1;
		}
	}
}
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 20px 20px 20px;

	a {
		align-self: stretch;
		text-align: center;
	}

	img {
		max-width: 80%;
		width: 300px;
		height: auto;
	}

	.info {
		font-size: 25px;
		text-align: center;
	}

	.success {
		text-align: center;
		padding: 20px;
	}

}
.addressValidation {
	text-align: center;

	h2 {
		margin: 0;
		font-size: 18px;
	}

	h3 {
		margin: 0;
		font-size: 16px;
	}

	&Address {
		margin-top: 10px;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 600;
		color: black;
		letter-spacing: 0.5px;
	}

	&Divider {
		width: 100%;
		height: 1px;
		margin: 10px 0;
		background-color: #ededed;
	}

	&Buttons {
		text-align: right;
		.ui.button {
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 1px;
			color: #ededed;
		}
	}

}
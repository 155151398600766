.main{
  display: flex;
  justify-content: center;
  align-items: center;



  .container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-evenly;
    max-width: 300px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    height: 350px;
    min-width: 300px;
    margin: 40px;
    padding-left: 40px;
    transition: all .15s ease-in-out;
    
    &:hover{
      transform: scale(1.05);
    }
  
    h2 {
      border-bottom: #626262 1px solid;
      padding: 10px;
      margin-bottom: 0px;
      margin-right: 40px;
    }
  }
}

.optionals1{

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 15px;
  margin: 15px;

  @media (max-height:100px){
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0);
  }
}
.optionals2{

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 15px;
  margin: 15px;

  @media (max-height:100px){
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0);
  }
}
.optionals3{

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 15px;
  margin: 15px;

  @media (max-height:100px){
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0);
  }

}


div.checkboxContainer {
	transition: .1s all;
	padding: 2px 5px;
	border-radius: 4px;

	&:hover {
		background-color: rgba(0,0,0,.05);
	}

	:global(.ui.checkbox) {
		label {
			&::before, &::after {
				margin-top: 5px;
			}
		}
	}
}

div.labelBox {

	h3 {
		font-size: 20px;
		margin-top: 0;
		margin-bottom: 2px;
		letter-spacing: 1px;
		text-transform: uppercase;
	}

	p {
		font-size: 16px;
		max-width: 600px;
		letter-spacing: 1px;
	}
}

.corpHeader {
  color: black;
  text-transform: uppercase;
  padding-left: 33px;
}

.corpSubHeader{
  max-width:600px;
  padding-left: 33px;
  font-size: 16px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.87);
}

div.checkboxContainerNoHover {
	transition: .1s all;
	padding: 2px 5px;
	border-radius: 4px;



	:global(.ui.checkbox) {
		label {
			&::before, &::after {
				margin-top: 5px;
			}
		}
	}
}
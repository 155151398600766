.container {


	:global(.ui.placeholder.segment) {
		min-height: 0;
		background-color: #f5f5f5;
	}

}

.currentMemberships {

	h3 {
		text-align: center;
		font-size: 20px;
	}

}

.membership {

	background-color: #ffffff;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	border-radius: 2px;
	margin: 15px 0px;
	padding: 10px;
	


	.info {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		div {
			margin: 10px 20px;

			h4 {
				margin: 0;
				font-size: 16px;
				color: #000;
			}

			h5 {
				margin: 0;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}

	.actions {
		display: flex;

		div {
			flex: 1;
		}

		span {
			font-size: 13px;
			cursor: pointer;
			&:hover {
				color: #d63031;
			}
		}

		button.viewMembershipButton:global(.ui.button) {
			margin-right: 10px;
			font-size: 13px;
			max-width: none;
		}
	}
}

.cancelButtons {
	display: flex;
	justify-content: space-between;

	button:global(.ui.button) {
		font-size: 14px;
	}
}

.modal {
	font-size: 18px;
}

.walletButtonsContainer {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		margin-right: 10px;
		cursor: pointer;

		&:hover {
			filter: brightness(.7);
		}
	}
}
.legal {
	display: flex;
	max-width: 1000rem !important;

	@include respond(phone) {
		flex-direction: column;
	}

	&__sidebar {
		width: 200px;
		flex-basis: calc(25% - 32px);
		margin: 16px;

		@include respond(phone) {
			flex-basis: 100%;
			align-self: center;
		}

		.ui.fluid.pointing.secondary.vertical.menu > a {
			font-size: 15px;
		}
	}
}

.policy {
	color: #3b4045 !important;
	flex-basis: calc(75% - 32px);
	margin: 16px;

	@include respond(phone) {
		flex-basis: 100%;
	}

	h1 {
		font-size: 27px;
	}

	h2 {
		text-transform: uppercase;
		font-size: 19px;
	}

	h3 {
		font-size: 15px;
	}

	p {
		font-size: 15px;
	}

	ul {
		margin-left: 30px;
		margin-bottom: 13px;
	}
}
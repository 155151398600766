.page {
	min-height: 90vh;
}

.content {

	h2 {
		text-align: center;
		font-size: 25px;
	}
	padding: 20px;


	:global(.ui.pointing.secondary.menu) {
		a:global(.item) {
			font-size: 1.8em;
		}
	}

}
.m2 {
	position: fixed;
	width: 100vw; height: 100vh;
	background-color: red;
	overflow-y: auto;
	top: 0; left: 0;
	z-index: 1001;
	background-color: rgba(#000, 0.5);
	display: flex;
	padding: 10px;

	.m2Modal {
		position: relative;
		display: flex;
		flex: 1;
		min-height: 200px;
		background-color: #fff;
		box-shadow: 0 0 10px rgba(#000, 0.15);
		border-radius: 3px;
		overflow: hidden;
		margin: auto;

		// Sizes
		&.medium {
			max-width: 700px;
		}

		// Close Icon
		.close {
			position: absolute;
			top: 5px; right: 8px;
			color: #EFECCA;
			font-size: 20px;
			cursor: pointer;

			&:hover {
				color: #d63031;
			}
			svg {
				display: block;
			}
		}

		.content {
			padding-top: 10px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			line-height: initial;
			text-align: center;
			background-color: #1e1e20;

			.id {
				font-size: 25px;
				color: #fff;
			}

			.plan {
				font-size: 18px;
				letter-spacing: 1px;
				color: #EFECCA;
			}

			.cardContainer {
				width: 100%;
				background-color: #ECF0F1;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 20px 10px;
				margin-top: 20px;

				.dnpcard {
					position: relative;
					max-width: 500px;
					color: #000;
					font-weight: 600;
					letter-spacing: .5px;
					font-size: 16px;
					user-select: none;
					border-radius: 3px;
					overflow: hidden;
					box-shadow: 0 0 20px rgba(#000, 0.40);
					
					img {
						width: 100%;
						height: auto;
						display: block;
					}

					.bottomLeftText {
						position: absolute;
						bottom: 10px; left: 10px;
						display: flex;
						flex-direction: column;
						text-align: left;

						span:last-child {
							font-weight: 400;
						}
					}

					.bottomRightText {
						position: absolute;
						bottom: 10px; right: 10px;
						display: flex;
						flex-direction: column;
						text-align: right;

						span:last-child {
							font-weight: 400;
						}
					}
				}
			}
		}


	}


}

$duration-enter: 300ms;
$duration-exit: 100ms;

.m2:not(.above-exit) {
	animation: m2-enter $duration-enter forwards;
	.m2Modal {
		animation: m2-modal-enter $duration-enter forwards;
	}
}

@keyframes m2-enter {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes m2-modal-enter {
	0% {
		transform: translateY(-100px);
	}
	100% {
		transform: translateY(0);
	}
}

// Animations
.above {
	&-enter, &-appear {
		opacity: 0;
		.m2Modal {
			transform: translateY(-100px);
		}
	}

	&-enter-active, &-appear-active {
		opacity: 1;
		transition: opacity $duration-enter;
		.m2Modal {
			transform: translateY(0);
			transition: transform $duration-enter;
		}
	}

	&-enter-done, &-appear-done {
		opacity: 1;
		.m2Modal {
			transform: translateY(0);
		}
	}

	&-exit {
		opacity: 1;
		.m2Modal {
			transform: translateY(0);
		}
	}

	&-exit-active {
		opacity: 0;
		transition: opacity $duration-exit;
		.m2Modal {
			transform: translateY(-50px);
			transition: transform $duration-exit;
		}
	}

	&-exit-done {
		opacity: 0;
		.m2Modal {
			transform: translateY(-50px);
		}
	}
}
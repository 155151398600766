.react-datepicker-wrapper {
	display: block !important;
}

.react-datepicker__input-container {
	width: 100% !important;

	input {
		width: 100% !important;
	}
}


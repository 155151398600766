.ui.form {
	.stripe {

		label {
			color: #2b3c51;
			font-weight: 300;
			letter-spacing: 0.025em;
		}

		input, .StripeElement {
			display: block;
			margin: 10px 0 20px 0;
			padding: 10px 14px;
			font-size: 1em;
			font-family: 'Source Code Pro', monospace;
			// box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
			// 	rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
			border: 1px solid rgba(34,36,38,.15);
			outline: 0;
			border-radius: 4px;
			background: white;
		}

		input::placeholder {
			color: #aab7c4;
		}

		input:focus, .StripeElement--focus {
			box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
				rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
			-webkit-transition: all 150ms ease;
			transition: all 150ms ease;
		}

	}
}
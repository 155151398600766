.plans {
	&__header {
		min-height: 70vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0rem 2rem;

		@include respond(phone) {
			min-height: 0;
		}

		&__h1 {
			font-size: 5rem;
			font-weight: 400;
			padding-top: 3rem;
			padding-bottom: 5rem;

			@include respond(phone) {
				font-size: 3rem;
				text-align: center;
				padding-bottom: 0rem;
			}
		}

		&__image {
			width: 70vw;

			@include respond(phone) {
				width: 100vw;
			}
		}

		&__paragraph {
			font-size: 3rem;
			max-width: 80vw;
			text-align: center;
			font-weight: 300;
			padding: 5rem 0rem;

			@include respond(phone) {
				font-size: 1.6rem;
				padding: 2rem 0rem;
			}
		}
	}

	&__content {
		background-color: $color-primary;
		background-image: url("../../assets/textures/diamonds-light.png");
		display: flex;

		@include respond(phone) {
			flex-direction: column;
		}

		&__image {
			max-width: 700px;
			max-height: 700px;
			height: auto;
			width: auto;
			padding: 8rem;

			@include respond(phone) {
				padding: 0rem;
				max-height: none;
				max-width: none;
				width: 100vw;
			}
		}

		&__section {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 8rem;

			@include respond(phone) {
				padding: 1rem;
			}

			&__paragraph {
				color: $color-white;
				text-align: right;
				font-size: 3.1rem;
				padding: 2rem;
				font-weight: 300;

				@include respond(phone) {
					font-size: 1.6rem;
					text-align: center;
				}
			}

			button {
				align-self: center;
				font-size: 2.6rem;
				padding: 2rem 3rem;
				font-weight: 300;
				letter-spacing: .5rem;
				@include respond(phone) {
					font-size: 1.8rem;
					padding: 1rem 1.5rem;
					letter-spacing: .1rem;
				}
			}
		}
	}
}
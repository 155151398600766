.faqpage {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__header h1.header__h1 {
		font-size: 5rem;
		text-align: center;
	}

	&__header {
		img {
			@include respond(phone) {
				width: 100vw;
				margin-top: 1.3rem;
			}
		}
	}

	.accordion {
		max-width: 900px;
		width: 100%;
		margin: 2rem 1rem;
		margin-bottom: 10rem;

		h3 {
			font-size: 2rem;
		}

		p {
			font-size: 1.6rem;
		}
	}

}
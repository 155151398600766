.btn {
	padding: 15px;
	border: none;
	border-radius: 2px;
	background-color: $color-primary;
	color: $color-white;
	text-transform: uppercase;
	font-size: 1.3rem;
	position: relative;
	cursor: pointer;
	transform: perspective(1px) translateZ(0);
	text-decoration: none;
}

.btn:hover,
.btn:active,
.btn:focus {
	outline: none;
}

.btn-open::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #2098D1;
	transform: scaleY(0);
	transform-origin: 50%;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}

.btn-open:hover::before,
.btn-open:active::before,
.btn-open:focus::before {
	transform: scaleY(1);
	outline: none;
}

.btn-right::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #2098D1;
	transform: scaleX(0);
	transform-origin: 0 50%;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}

.btn-right:hover::before,
.btn-right:active::before,
.btn-right:focus::before {
	transform: scaleX(1);
	outline: none;
}

.btn-shadow {
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.56);
}


.btn-text {
	color: $color-primary;
	border: none;
	border-bottom: 1px solid $color-primary;
	font-size: 2.2rem;
	text-transform: uppercase;
	font-weight: 300;
	cursor: pointer;
	transition: all .2s;
}

.btn-text::after {
	content: "→";
	margin-left: 80px;
	transition: opacity .3s, margin .3s;
	opacity: 0;
	position: absolute;
}

.btn-text:hover,
.btn-text:active,
.btn-text:focus {
	outline: none;
	color: $color-btn-text-hover;
	border-bottom: 1px solid $color-btn-text-hover;
}

.btn-text:hover::after,
.btn-text:active::after,
.btn-text:focus::after {
	display: inline-block;
	margin-left: 10px;
	opacity: 1;
}

.btn-alt {
	padding: 0.7rem 1rem;
	border: 3px solid $color-textbox-border;
	border-radius: 2px;
	background-color: $color-textbox-fill;
	color: $color-primary;
	text-transform: uppercase;
	font-size: 1.3rem;
	transition: all .3s;
	cursor: pointer;
}

.btn-alt:hover,
.btn-alt:active,
.btn-alt:focus {
	border-color: #2098D1;
	outline: none;
}

.wizard-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;

	button:only-child {
		margin-left: auto;
	}
}

.btn-add {
	font-size: 1.2rem;
	background: #ff4351;
	cursor: pointer;
	border-radius: 10px;

	color: white;
	transition: color .2s;

	i {
		padding: .98rem 1.2rem;
	}
}

.btn-add:hover {
	background: #ff7680;
}

button.button-stripe {
	text-align: center;
	background-color: #6772e5;
	box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
	transition: all .15s ease;
	margin: 3rem 0rem;
	line-height: normal;
	padding: 1rem 2rem;

	&:hover {
		background-color: #7795f8;
		box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
		transform: translateY(-1px);
	}

	i {
		font-size: 5rem;
		text-align: center;
	}

}

.format-stripe-payment {
	display: flex;
	flex-direction: column;
	align-items: center;

	.error {
		color: #ff4351;
		font-size: 1.6rem;
	}
}


.api__buttons {
	display: flex;
	flex-direction: column;
	

	button {
		padding: 10rem 10rem;
		font-size: 2rem;
		border-radius: 2px;
		color: #eee;
		cursor: pointer;
		transition: all .2s;
		position: relative;

		i {
			margin-bottom: 1rem;
			display: block;
			font-size: 7rem;
			color: black;
			transition: all .2s;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	button:not(:last-child) {
		margin-bottom: 1rem;
	}

	button:hover {
		font-size: 1rem;

		i {
			font-size: 9rem;
		}
	}

	.youtube {
		background-color: #cd201f;
	}

	.linkedin {
		background-color: #0077B5;
	}

	.youtube:hover {
		background-color: #f36262;
	}

	.linkedin:hover {
		background-color: rgb(25, 160, 233);
	}
}

.btn-blink {
	animation: blink 5s;
	animation-iteration-count: infinite;
	position: relative;
	background-color: #74b9ff;
}


@keyframes blink {
	0% {
		background-color: #74b9ff;
	}

	25% {
		background-color: #ff7675;
	}

	50% {
		background-color: #55efc4;
	}

	75% {
		background-color: #a29bfe;
	}

	100% {
		background-color: #74b9ff;
	}
}
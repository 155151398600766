// .form {
// 	padding: 1rem;
// 	width: 100%;
// }

// .form-group {
// 	padding: 1rem .2rem;
// }

// .label {
// 	display: block;
// 	font-size: 1.6rem;
// }

// .input {
// 	width: 100%;
// 	border-radius: 2px;
// 	background-color: $color-textbox-fill;
// 	border: 1px solid $color-textbox-border;
// 	padding: 1rem;
// }

// .input--alt {
// 	width: 100%;
// 	padding: 1rem;
// 	background-color: $color-textbox-fill;
// 	border: 2px solid $color-textbox-border;
// 	border-radius: 2px;
// 	transition: all .3s;
// }

// .input--alt:focus {
// 	outline: none;
// 	border-color: #2098D1;
// }

// .input--alt::-webkit-input-placeholder {
// 	font-weight: 400;
// 	color: $color-primary;
// }

// .form-loading {
// 	position: relative;
// 	cursor: default;
// 	pointer-events: none;

// 	&-spinner {
// 		position: absolute !important;
// 		top: 50%;
// 		left: 50%;
// 		transform: translate(-50%, -50%);
// 		z-index: 101;
// 		width: 5rem !important;
// 		height: 5rem !important;
// 		color: $color-btn-text-hover !important;
// 	}
// }

// .form-loading::before {
// 	position: absolute;
// 	content: '';
// 	top: 0;
// 	left: 0;
// 	background: rgba(255, 255, 255, .8);
// 	width: 100%;
// 	height: 100%;
// 	z-index: 100;
// }


// .field {
// 	display: flex;
// 	flex-direction: column;
// 	flex: 1 1 auto;

// 	input {
// 		width: auto;
// 		margin: 0;
// 		outline: none;
// 		border: 1px solid rgba(34,36,38,.15);
// 		transition: color .1s ease, border-color .2s ease;
// 		padding: .95rem 0rem;
// 		padding-left: 1.4rem;
// 		padding-right: 1.4rem;
// 		border-radius: 2px;
// 		max-width: 100%;
// 		font-size: 1.4rem;
// 		color: rgba(0,0,0,.95);
// 	}

// 	textarea {
// 		width: auto;
// 		margin: 0;
// 		outline: none;
// 		border: 2px solid rgba(34,36,38,.15);
// 		transition: color .1s ease, border-color .2s ease;
// 		padding: .95rem 0rem;
// 		padding-left: 1.4rem;
// 		padding-right: 1.4rem;
// 		border-radius: 2px;
// 		max-width: 100%;
// 		font-size: 1.4rem;
// 		color: rgba(0,0,0,.95);
// 		resize: vertical;
// 	}

// 	input:focus {
// 		border-color: #85b7d9 !important;
// 	}

// 	textarea:focus {
// 		border-color: #85b7d9 !important;
// 	}

// 	input.icon {
// 		padding-left: 2rem;
// 	}

// }

// .field.bordered {
// 	input {
// 		border: 3px solid rgba(34, 36, 38, 0.15);
// 	}
// }

// .ui {
// 	display: flex;
// 	flex-direction: column;
// 	flex: 1 1 auto;
// 	width: 100%;

// 	span.error {
// 		color: #9f3a38;
// 	}

// 	label {
// 		display: block;
// 		font-size: 1.3rem;
// 		font-weight: 600;
// 		margin-bottom: .4rem;
// 		color: rgba(0,0,0,.87);
// 	}

// }

// .ui.required {
// 	label::after {
// 		margin: -.2em 0 0 .2em;
// 		content: "*";
// 		color: #db2828;
// 	}
// }

// .fields.equal {
// 	display: flex;
// 	flex-direction: row;
// }

// .fields.equal > .ui {
// 	padding: 0rem .5rem;
// }


.ui .field .Select-input input {
	border: none;
}

input {
	font-size: 1.4rem;
}

.ui.form .field>label {
	font-size: 1.4rem;
}

.ui.form {
	font-size: 1.4rem;
}

.formDivider {
	background-color: #ededed;
	height: 1px;
	width: 100%;
	margin: 10px 0;
}

.formHeader {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	color: #2a80b9;
	margin-bottom: 10px;

	& + p {
		margin-top: 3px !important;
		margin-bottom: 5px !important;
	}
}
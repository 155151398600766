.container {
  height: 70px;
  background-color: #2f3640;
  // box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;

  position: sticky;
  top: 0;

  &.home {
    display: none;

    @media (max-width: 900px) {
      display: flex;
    }
  }

  img {
    margin-left: 50px;
    height: 45px;
    width: auto;
  }

  .links {
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;

    a {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      margin-right: 40px;
      text-transform: uppercase;
      letter-spacing: 2px;
      transition: 0.2s color;

      @media (max-width: 1150px) {
        font-size: 16px;
      }

      @media (max-width: 1060px) {
        font-size: 14px;
      }

      @media (max-width: 980px) {
        font-size: 12px;
      }

      @media (max-width: 910px) {
        font-size: 11px;
      }
    }

    a:hover {
      color: #dcdde1;
    }

    .homeLink {
      margin-left: 30px;
    }
  }
}

.full {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }

  &.home {
    display: none;
  }
}

.mobile {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 901px) {
    display: none;
  }
}

.sideMenu {
  display: block;
  position: absolute;
  z-index: 100;
  width: 80%;
  height: 100vh;
  top: 0;
  left: -120%;
  background-color: #1e252b;
  transition: 0.5s left;

  @media (min-width: 901px) {
    display: none;
  }
}

.sideMenu.open {
  left: 0;
  position: fixed;
}

.hamburgerContainer {
  margin-right: 15px;
}

.mobileLinks {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 25px;
  align-items: stretch;

  img {
    margin: 20px 0;
    width: 200px;
    height: auto;
  }

  a,
  h3 {
    cursor: pointer;
    font-size: 25px;
    font-weight: 400;
    color: #f1f2f6;
    transition: 0.2s all;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:hover {
      color: #f8efba;
    }
  }
}

.dropDown {
  position: relative;
  margin-right: 30px;
  display: flex;
  align-items: center;

  & > h3 {
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.2s color;
    cursor: pointer;

    @media (max-width: 1150px) {
      font-size: 16px;
    }

    @media (max-width: 1060px) {
      font-size: 14px;
    }

    @media (max-width: 980px) {
      font-size: 12px;
    }

    @media (max-width: 910px) {
      font-size: 11px;
    }
  }

  .dropDownMenu {
    background: #ffffff;
    position: absolute;
    top: 110%;
    right: 3px;
    min-width: 150px;
    color: #000;
    border-radius: 1px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.29);

    display: none;
    flex-direction: column;

    text-align: center;

    &.open {
      display: flex;
    }

    & > div,
    a {
      padding: 10px;
      cursor: pointer;
      letter-spacing: 1px;
      font-size: 16px;
      color: #000;
      text-transform: none;
      font-weight: 500;
      margin: 0;

      &:hover {
        background-color: rgba(#74b9ff, 0.3);
      }
    }

    & > span:not(.seperator) {
      padding: 10px;
    }
  }

  .seperator {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    padding: 0;
  }
}

.mobileAccountMenu {
  display: none;
  opacity: 0;
  position: absolute;

  &.open {
    flex: 1;
    // border-top: 1px solid #fff;
    display: flex;
    flex-direction: column;
    color: black;
    padding: 20px;
    justify-content: center;
    align-items: center;
    animation: example 0.5s;
    animation-fill-mode: forwards;
    width: 100%;

    background-color: #ecf0f1;
    width: 100%;
    flex: 1;

    div,
    a {
      cursor: pointer;
      width: 100%;
      color: #000;
      text-transform: none;

      &:hover {
        color: #3498db;
      }
    }
  }
}

@keyframes example {
  from {
    opacity: 0;
    top: 20px;
  }

  to {
    opacity: 1;
    top: 50px;
  }
}

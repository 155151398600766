div.modal-login-header {
	font-size: 2.5rem;
}

form.form-login {
	button {
		float: right;
		padding: 1rem 3rem;
	}

	button::before {
		transition-duration: .15s;
	}
}
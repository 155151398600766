.loginOrContinue {
	padding: 2rem 0;
	text-align: center;

	button {
		padding: 3rem 2rem;
		font-size: 2rem;
		font-family: inherit;
		text-transform: uppercase;
		font-weight: 300;
		background-color: #27ae60;
		color: $color-white;
		cursor: pointer;
		border: 2px solid #eee;
		border-radius: 2px;
		width: 30rem;
	}

	p {
		padding: 2rem;
		font-size: 1.7rem;
		margin: 0 auto;
	}

	button:nth-of-type(2) {
		background-color: #34495e;
	}

	.ui.horizontal.divider {
		font-size: 16px;
	}

}
.compenstation-option {
	padding: 1rem;
	border: 3px solid #eee;

	label:not(:last-child) {
		margin-bottom: 1rem;
	}

	label {
		cursor: pointer;
		display: block;
		padding-left: 1rem;
	}

	label:hover {
		background-color: #eee;
	}

	h5 {
		display: inline-block;
		margin-left: 1rem;
		font-size: 1.5rem;
	}

	li {
		margin-left: 1rem;
	}

}

.additionalEmployees {
	padding: 1rem;
	margin-top: 1rem;
	border: 3px #ddd solid;

	&__header {
		h5 {
			font-size: 1.5rem;
		}
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.employee-member {

		& > div {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			display: flex;
			align-items: flex-end;


			& > * {
				margin-bottom: 1rem;
			}

			& > *:not(:last-child) {
				margin-right: 1rem;
			}
		}


	}

	
}

.check-membership {
	display: flex;
	flex-direction: column;

	p {
		font-size: 1.4rem;
	}

	a {
		text-decoration: none;
		font-size: 1.8rem;
		transition: all .1s;
		margin: 2rem auto;
		color: #3498db;
	}

	a:hover {
		color: #e67e22;

	}

	span {
		margin: 1rem;
		font-weight:300;
		
	}

	

}

.testContainer {
 display: flex;
 justify-content: center;
 font-size: 16px;
 margin: 10px;

 .ui.button {
	 font-size: 18px;
 }
}

div.videoError {

	span {
		color: red;
	}

}

div.videoError, div.videoCenter {
	display: flex;
	// justify-content: center;
	flex-direction: column;
	align-items: center;
}
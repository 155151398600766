.contract {
	margin: 1rem 0;
	color: #000;
	
	h4 {
		color: #d35400;
		font-size: 2rem;
	}

	p {
		font-size: 1.6rem;
	}

	&__box {
		overflow: auto;
		width: 100%;
		max-height: 20rem;
		border: #000 solid 1px;
		padding: 1.5rem 2rem;
		margin: 1rem 0;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	}

}
.forgotPasswordLink {
	display: flex;
	p {
		cursor: pointer;
		transition: .2s all;

		&:hover {
			color: #74b9ff;
		}
	}
}

.passwordReset {

	.resetButton {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

}

.passwordResetConfirm {
	span {
		cursor: pointer;

		&:hover {
			color: #74b9ff;
		}
	}
}
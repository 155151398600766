.__react_component_tooltip.type-light {
	background-color: #fff;
	max-width: 350px;
	box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
	border: 1px solid #d4d4d5;
}

.__react_component_tooltip.show.type-light {
	opacity: 1;
}

.__react_component_tooltip.type-light::after {
	box-shadow: 1px 1px 0 0 #bababc;
	opacity: 0;
}
.modal {
	position: fixed;
	z-index: 100001;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: auto;
	background-color: #000;
	opacity: 0.5;
}

.modal-content {
	position: absolute;
	width:80%;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	border: 1px solid #070505;
	background-color: white;
	box-shadow: 0 5px 15px rgba(0,0,0,.5);
	border-radius: 1px;
	padding: 2.4rem;
	margin-top: 20px;
	margin-bottom: 20px;

	@media only screen and (max-width: 550px) {
		width: 95%;
	}
}


.modal-small {
	max-width: 40%;
	min-width: 400px;
}

.modal-medium {
	max-width: 60%;
	min-width: 400px;
}

.modal-fluid {
	width: auto;
}

.modal-close {
	position: absolute;
	cursor: pointer;
	top: 16px;
	right: 16px;
	width: 16px;
	height: 16px;
}

.modal-close::before,
.modal-close::after {
	content: "";
	position: absolute;
	height: 2px;
	width: 100%;
	top: 50%;
	left: 0;
	margin-top: -1px;
	background: #999;
	border-radius: 100%;
	transition: background .2s;
}

.modal-close::before {
	transform: rotate(45deg);
}

.modal-close::after {
	transform: rotate(-45deg);
}

.modal-close:hover::before,
.modal-close:hover::after {
	background-color: #333;
}

.modal-content:focus {
	outline: none;
}

.modal-header {
	font-size: 1.6rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #eee;
}

.modal-body {
	padding-top: 1rem;
	font-size: 1.3rem;
	// overflow: auto;
}

// .loader {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0,0,0,.5);
// }

.noClick {
	pointer-events: none;
}
.loginForm {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.center {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

}
.main{
	background-color: #fdd384;
	width: 350px;
	height: 160px;
	color: #000;
	padding: 15px;
	transition: all .2s ease-in-out;
	cursor: pointer;
	border-radius: 3px;
	margin-bottom: 10px;

	@media (max-width: 1150px) {
		margin-bottom: 10px;
	}

	&:hover {
		transform: scale(1.1);
		// box-shadow: 0px 0px 10px #1CD4F9;
	}
}
.container{

	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: space-evenly;

	h1{
		font-size: 28px;
		text-align: center;
		font-weight: 600;
		font-family: 'Montserrat', sans-serif;
	}

	h3{
		font-size: 20px;
		text-align: center;
		margin-top: 0px;
		font-weight: 500;
		font-family: 'Montserrat', sans-serif;
	}
	
}
.footer__topline {
	border-top: 3px solid $color-primary;
}

.footer {
	// display: flex;
	// flex-direction: column;
	width: 100%;
	padding: 0 3rem;
	padding-bottom: 1rem;
	// flex: 1;
	margin: 0 auto;
	position: relative;

	&__map {
		display: flex;
		align-items: flex-start;
		flex: 1;
		justify-content: space-between;
		margin: 2rem 0;

		@include respond(phone) {
			flex-wrap: wrap;
			margin-bottom: -4rem;
		}

		div {
			display: flex;
			justify-content: space-evenly;
			flex-direction: column;
			font-size: 1.6rem;

			@include respond(phone) {
				justify-content: center;
				margin-bottom: 2rem;
				flex: 1 1 50%;
			}

			p {
				color: $color-textbox-border;
				font-weight: 400;
				font-size: 1.8rem;
				text-transform: uppercase;
			}

			a {
				color: $color-primary;
				text-decoration: none;
				margin-top: 3rem;
				font-weight: 300;
				@include respond(phone) {
					margin-top: 1rem;
				}
			}

			a:hover {
				color: $color-textbox-border;
			}
		}
	}

	&__line {
		border-bottom: $color-primary 5px solid;
		border-radius: 3px;
		align-self: center;
		width: 100%;
	}

	&__bottom {
		font-size: 1.4rem;
		margin: 1.7rem 0rem;
	}
}
.seminar {
	&__confirmation {
		text-align: center;

		h3 {
			font-size: 30px;
		}

		p {
			font-size: 20px;
		}

		&__icon {
			color: #2ecc71;
			font-size: 50px;
		}
	}
}
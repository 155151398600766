.container {
	background-color: #e3e8ee;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;
	max-width: 100vw;

	a  > img {
		width: 200px;
		height: auto;
	}


	.informationContainer {
		width: 100%;
		margin: 10px;
		max-width: 550px;
		padding: 20px;
		color: #2a2f45;
		// border: 1px solid black;
		border-radius: 3px;
		background-color: #fff;
		box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);

		button {
			font-size: 15px;
		}
	}

	h3 {
		text-align: center;
		font-size: 20px;
	}

	h4 {
		text-align: center;
		margin-top: 0px;
		font-size: 15px;
	}

}

.pricing {

	&__header {
		max-height: 90vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0rem 2rem;

		&__h1 {
			font-size: 5rem;
			font-weight: 400;
			padding-top: 3rem;
			padding-bottom: 5rem;

			@include respond(phone) {
				font-size: 4rem;
			}
		}

		&__image {
			width: 70vw;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		padding: 10rem;
		align-items: center;

		@include respond(phone) {
			padding: 1rem;
			margin-top: 2rem;
		}

		&__title {
			font-size: 5rem;
			font-weight: 400;
			padding-top: 1rem;
			padding-bottom: 5rem;
			@include respond(phone) {
				font-size: 3rem;
				text-align: center;
				padding-bottom: 0rem;
				// border-bottom: 1px solid $color-primary;
			}
		}

		&__content {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			padding: 1rem 1rem;

			@include respond(phone) {
				flex-direction: column;
			}

			&__point {
				flex: 0 0 33%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				margin: 0 2rem;
				padding: 1rem 3rem;

				@include respond(phone) {
					flex: 1 1 100%;
					padding: 1rem 0rem;
				}

				img {
					width: 15rem;
					height: 15rem;
					@include respond(phone) {
						width: 5rem;
						height: 5rem;
						margin-bottom: 1rem;
					}
				}

				p {
					text-align: center;
					font-size: 2.6rem;
					@include respond(phone) {
						font-size: 1.4rem;
					}
				}
			}
		}
	}

	&__companies {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 3rem;

		h3 {
			font-size: 5rem;
			font-weight: 400;
			padding-top: 1rem;
			padding-bottom: 2rem;
		}

		&__animated {
			width: 80vw;
			height: 390px;
			background-color: $color-grey-light-2;
		}
	}

	&__plans {
		background-color: $color-primary;
		background-image: url("../../assets/textures/diamonds-light.png");
		display: flex;
		flex-direction: column;
		align-items: center;

		&__card {
			max-width: 1190px;
			// max-height: 550px;
			border: $color-primary 3px solid;
			display: flex;
			background-color: $color-textbox-fill;
			margin: 3rem 0;

			@include respond(phone) {
				flex-direction: column;
				max-width: 100vw;
				max-height: none;
				overflow: hidden;
			}

			img {
				max-width: 475px;
				// max-height: 396px;
				width: auto;
				height: auto;
				flex: 0 0 40%;

				@include respond(phone) {
					max-width: 100vw;
					width: 100vw;
					height: auto;
					overflow: hidden;
				}
			}

			&__content {
				// flex: 0 0 60%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;

				h3 {
					font-size: 3.8rem;
					font-weight: 300;
					margin-top: 2rem;
					@include respond(phone) {
						font-size: 3rem;
						margin-top: 1rem;
						text-align: center;
					}
				}

				h4 {
					font-size: 3rem;
					@include respond(phone) {
						font-size: 3rem;
						padding: 0rem 1rem;
						text-align: center;
					}
				}

				p {
					text-align: center;
					font-size: 1.8rem;
					line-height: 3rem;
					padding: 1rem 3rem;
					font-weight: 300;

					@include respond(phone) {
						font-size: 1.4rem;
						padding: 0rem 1rem;
						text-align: justify;
						line-height: 1.6rem;
					}
				}

				&__buttons {
					align-self: flex-end;
					display: flex;
					justify-content: flex-end;
					align-items: flex-end;
					padding: 1rem;



					button {
						padding: .8rem 3rem;
					}

					*:not(:last-child) {
						margin-right: 1rem;
					}
					
				}
			}
		}

		&__card:nth-child(even) {
			flex-direction: row-reverse;

			@include respond(phone) {
				flex-direction: column;
			}

			.pricing__plans__card__content {
				&__buttons {
					align-self: flex-start !important;
				}
			}
			
		}
	}
}



*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
	font-size: 62.5%;
	min-height: 100vh;
	height: initial;
}

body {
	box-sizing: border-box;
	min-height: 100vh;
	height: initial;
}

main {
	position: relative;
	background: linear-gradient(to bottom,  rgb(254, 246, 232) 0%,rgba(255,221,155,1) 100%);
}
main:after {
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	content: "";
	filter: blur(250px);
	z-index: -1;
	position: absolute;
	transform: translate(-50%, -50%);
	background: radial-gradient(ellipse at center, rgb(81, 81, 81) 0%, rgba(184, 184, 184, 0) 40%);
}

::selection {
    background-color: $color-primary;
    color: $color-white;
}

#root {
	min-height: 100vh;
	max-width: 100vw;
	overflow: hidden;
}

.App {
	min-height: 100vh;
}

body.no-overflow {
	overflow: hidden;
	height: 100vh;
}

span.error {
	color: #d63031;
	font-size: 13px;
}

.contract2 {
	height: 200px;
	overflow-y: scroll;
	width: 100%;
	padding: 10px 40px;
	border-radius: 5px;
	// background-color: #f4f8f7;
	border: 1px solid #e4eaef;
	margin-bottom: 10px;
}

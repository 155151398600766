.window {
	box-shadow: 0 16px 18px rgba(0,0,0,.2);
	width: 400px;
	height: 650px;
	background: none;
	position: fixed;
	bottom: 15px; right: 15px;
	z-index: 100;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	display: flex;
	flex-direction: column;
}

.headerContainer {
	// background: #006cff;
	background: #f4b62e;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	color: #fff; 
	height: 70px;
	position: relative;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	img {
		height: 100%;
		width: auto;
		position: absolute;
		left: 10%;
		bottom: -8px;
		transform: translateX(-50%);
	}
}

.headerName {
	// text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 20px;
	font-weight: 700;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.topLine {
	height: 1px;
	width: 100%;
	background-color: #ededed;
	z-index: 2;
}

.conversationContainer {
	flex: 1;
	background: rgba(#fff, .98);
	overflow-y: scroll;
	padding-bottom: 20px;
}

.inputContainer {
	// border-top: 1px solid #ededed;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(#fff, .98);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;

	input {
		padding: 10px;
		background: none;
		border: none;
		flex: 1;
		font-size: 16px;
		font-family: 'Lato', sans-serif;

		&:focus {
			outline: none;
		}

		&::-webkit-input-placeholder,
		&:-ms-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder {
			font-family: 'Lato', sans-serif;
		}
	}

	div {
		padding: 3px;
		color: #3398db;
		transition: .2s all;

		&[disabled] {
			color: #c2c2c2;
		}

		&:hover:not([disabled]) {
			cursor: pointer;
			color: #273c75;
		}
	}
}


.chatMessageContainer {
	padding: 10px;
	display: flex;
	flex-direction: column;

	.chatMessageDate {
		text-align: center;
		color: rgba(0, 0, 0, 0.7);
		font-size: 12px;
		margin-bottom: 5px;
		font-weight: 300;
	}

	.chatMessage {
		background-color: #e9eef2;
		color: rgb(100, 100, 100);
		border-radius: 8px;
		padding: 7px 12px;
		display: inline-block;
		max-width: 80%;
		font-size: 15px;
		align-self: flex-start;
	}

	&.user {
		.chatMessage {
			align-self: flex-end;
			background-color: #00a8ff;
			color: #ecf0f1;
			border-radius: 15px;
		}
	}

}

.actionContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;


	.singleLink {
		align-self: center;
		background-color: #2b3e50;
		padding: 8px 20px;
		color: #fff;
		font-size: 18px;
		border-radius: 3px;
		font-family: 'Lato', sans-serif;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:hover {
			background-color: #34495e; 
		}
	}
}
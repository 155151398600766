.addMemberModalContainer{

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;

	.modalHeader{
		color:black
	}
	
	.modalSubHeader{
		color: black;
		font-size: 16px;
		letter-spacing: 1px;
	}

	.modalDivider{
		margin: 20px;
		height:2px;
		width:98%;
		border-top: solid 1px rgba(0,0,0,0.65);
	}
	
	.modalImage{
		max-width: 90%;
	}

}



.family-member {
	border: #ededed 1px solid;
	// box-shadow: 0px 0px 5px rgba(#000, .26);
	padding: 1rem 2rem;
	margin: 1rem 0;
	position: relative;
	background-color: #ecf0f1;

	&__delete {
		position: absolute;
		top: 5px;
		right: 5px;
		cursor: pointer;
		font-size: 20px;
		color: #e74c3c;
		display: block;

		svg {
			display: block;
		}
	}

	h4 {
		// border-bottom: 1px solid #ededed;
		font-size: 1.6rem;
		color: #2c3e50;
	}
}